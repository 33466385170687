/* ======================================================================= */
/* ========================= >>> VARIÁVEIS <<< =========================== */
/* ======================================================================= */

// CORES
$cor_01: #480718;
$cor_02: #b99038;
$cor_03: #ece4d1;
$cor_04: #BE8E2C;
$cor_05: #D0BB85;
$cor_06: #faf9f4;
$cor_text: #5d5d5d;
$screen_ss: 375px;
$screen_xs: 767px;
$screen_sm: 768px;
$screen_md: 992px;
$screen_desktop_sm: 1270px;
$screen_desktop_md: 1350px;
$screen_desktop_lg: 1430px;