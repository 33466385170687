/* ======================================================================= */
/* =========================== >>> MIXINS <<< ============================ */
/* ======================================================================= */

/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {Style}           - text-shadow
@mixin stroke($stroke, $color) {
	text-shadow: stroke($stroke, $color);
}

@mixin fontes($file, $family, $weight:500, $style:normal, $tipo_01:'woff2') {
	@font-face {
		font-family: $family;
		src: url(../fonts/#{$file}.#{$tipo_01}) format($tipo_01);
		font-weight: $weight;
		font-style: $style;
	}
}

@mixin animado {
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}

@mixin placeholder {
	::-webkit-input-placeholder {
		@content
	}
	:-moz-placeholder {
		@content
	}
	::-moz-placeholder {
		@content
	}
	:-ms-input-placeholder {
		@content
	}
}
@mixin media_screen_min($valor_screen) {
	@media (min-width: $valor_screen) {
		@content;
	}
}
@mixin media_screen_max($valor_screen) {
	@media (max-width: $valor_screen) {
		@content;
	}
}
///
/// Viewport sized typography with minimum and maximum values
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback),
///                 minumum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
	$responsive-unitless: $responsive / ($responsive - $responsive + 1);
	$dimension: if(unit($responsive) == 'vh', 'height', 'width');
	$min-breakpoint: $min / $responsive-unitless * 100;

	@media (max-#{$dimension}: #{$min-breakpoint}) {
		font-size: $min;
	}

	@if $max {
		$max-breakpoint: $max / $responsive-unitless * 100;

		@media (min-#{$dimension}: #{$max-breakpoint}) {
			font-size: $max;
		}
	}

	@if $fallback {
		font-size: $fallback;
	}

	font-size: $responsive;
}

// FUNCTION
/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {List}            - text-shadow list
@function stroke($stroke, $color) {
	$shadow: ();
	$from: $stroke*-1;
	@for $i from $from through $stroke {
		@for $j from $from through $stroke {
			$shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
		}
	}
	@return $shadow;
}