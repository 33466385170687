@import "variables";
@import "mixins";
@import "../components/bootstrap/scss/functions";
@import "../components/bootstrap/scss/variables";
@import "../components/bootstrap/scss/mixins";

/* =======================================================================
   FONT EXTERNAL IMPORT
   ======================================================================= */
@include fontes('DancingScript-Regular', 'Dancing Script', 400, normal);
@include fontes('DancingScript-Bold', 'Dancing Script', 700, normal);
@include fontes('Sintony', 'Sintony', 400, normal);
@include fontes('Sintony-Bold', 'Sintony', 700, normal);

/* =======================================================================
   TAGS AND CLASS
   ======================================================================= */
html {
	position: relative;
	min-height: 100%;
}

body {
	font-family: 'Sintony', sans-serif;
	font-weight: 400;
	font-size: 20px;
	@include media-breakpoint-down(md) {
		font-size: 16px;
	}
	line-height: inherit;
	color: $cor_text;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
	-webkit-text-stroke: 1px transparent;
}

button {
	cursor: pointer;
	border: none;
}

a {
	color: $cor_01;

	&:hover {
		color: $cor_02;
	}
}

select[readonly] {
	background: #eee;
	pointer-events: none;
	touch-action: none;
}

@include media_screen_min($screen_desktop_sm) {
	.container {
		max-width: 1240px !important;
	}
}

@include media_screen_min($screen_desktop_md) {
	.container {
		max-width: 1320px !important;
	}
}

@include media_screen_min($screen_desktop_lg) {
	.container {
		max-width: 1400px !important;
	}
}

pre {
	counter-reset: line-numbering;
	background: #2c3e50;
	padding: 12px 0px 14px 0;
	color: #ecf0f1;
	line-height: 140%;
	font-size: .8em;

	.line::before {
		content: counter(line-numbering);
		counter-increment: line-numbering;
		padding-right: 1em;
		/* space after numbers */
		padding-left: 8px;
		width: 1.5em;
		text-align: right;
		opacity: 0.5;
		color: white;
	}
}

.loading {
	width: 100%;
	height: 100%;
	margin: 0;
	top: 0;
	left: 0;
	position: absolute;
	background: rgba(255, 255, 255, .5);
	z-index: 9998;

	.infos {
		position: fixed;
		top: calc(50% - 200px);
		left: calc(50% - 50px);
		background: #ffffff;
		padding: 10px;
		border-radius: 5px;
		// border: 1px solid $cor_01;
		text-align: center;
		-webkit-box-shadow: 0 2px 5px 2px rgba(0, 0, 0, .3);
		box-shadow: 0 2px 5px 2px rgba(0, 0, 0, .3);
		overflow: hidden;

		h3 {
			margin-top: -30px;
			font-size: 1em;
		}

		img {
			display: inline-block;
			margin-top: -30px;
		}
	}
}

.gj-picker-bootstrap {
	font-size: .8em;

	table {
		tr {
			th {
				color: #000000;
			}

			td {
				&.other-month {
					div {
						color: #ccc;
					}
				}

				&.current-month {
					div {
						color: #333;
					}
				}
			}
		}
	}
}

.call-area {
	text-align: center;
	padding: 20px 0;

	h3 {
		font-family: 'Dancing Script', sans-serif;
		font-size: 3em;
		color: $cor_01;
		padding: 0;
		margin: 0;
	}

	h2 {
		font-weight: 700;
		font-size: 2.5em;
		padding: 0;
		margin: 0;
		text-transform: uppercase;
		color: $cor_02;
		display: inline-block;
		position: relative;

		&:after {
			content: "";
			background: $cor_02;
			height: 3px;
			width: 70%;
			position: absolute;
			bottom: -5px;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&.invert {
		h3 {
			color: $cor_02;
		}

		h2 {
			color: $cor_01;

			&:after {
				background: $cor_01;
			}
		}
	}

	p {
		padding: 20px 0;
		font-size: 1em;
	}
}

.institutional-content {
	h1, h2, h3, h4, h5, h6 {
		color: $cor_01;
		font-weight: 700;
	}

	p {
		padding: 0 0 20px 0;
		font-size: 1em;
		line-height: 1.5em;
	}
}

/* =======================================================================
   PACE
   ======================================================================= */
.pace {
	.pace-progress {
		background: $cor_01;
		height: 4px;
	}

	.pace-activity {
		top: 15px;
		right: 15px;
		width: 20px;
		height: 20px;
		border: solid 3px transparent;
		border-top-color: $cor_01;
		border-left-color: $cor_01;
		border-radius: 10px;
	}
}

/* =======================================================================
   HEADER
   ======================================================================= */
header {
	background: #fcfcf5;
	position: relative;
	z-index: 1;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);

	&:after {
		content: "";
		position: absolute;
		z-index: -1;
		top: 100%;
		bottom: 0;
		width: 100%;
		height: 40px;
		left: 0;
		background: -webkit-radial-gradient(50% -3%, ellipse cover, rgba(00, 00, 00, 0.5), rgba(97, 97, 97, 0.0) 40%);
		background: radial-gradient(ellipse at 50% -3%, rgba(00, 00, 00, 0.5), rgba(97, 97, 97, 0.0) 40%);
	}

	.info-header {
		padding: 5px 0;

		a {
			font-size: .9em;
			font-weight: bold;
			color: $cor_text;
			display: inline-block;
			margin-left: 10px;

			&:first-child {
				margin-left: 0;
			}

			&:hover {
				text-decoration: none;
			}

			img {
				width: 20px;
				vertical-align: sub;
			}
		}

		.phone-top {
			text-align: right;
			@include media-breakpoint-down(md) {
			}

			p {
				padding: 0;
				margin: 0;
			}

			a {
				font-size: 1em;
				font-weight: bold;
				color: $cor_text;
				position: relative;
				display: block;
				white-space: nowrap;
				padding: 5px 10px;

				small {
					font-weight: bold;
				}

				span {
					display: block;
					font-weight: normal;
				}

				img {
					float: left;
					width: 30px;
					margin-right: 10px;
					margin-top: 5px;
				}
			}
		}

		.address-top {
			@include media-breakpoint-down(sm) {
				display: none;
			}

			p {
				padding: 0;
				margin: 0;
			}

			font-size: 0.7em;
			font-weight: bold;
			color: $cor_text;
			position: relative;
			display: block;
			white-space: nowrap;
			padding: 5px 10px;

			small {
				font-weight: bold;
			}

			span {
				display: block;
				font-weight: normal;
			}

			img {
				float: left;
				width: 30px;
				margin-right: 10px;
			}
		}
	}

	.base-header {
		background: #ffffff;
		padding: 10px 0;

		.logo {
			display: block;
			@include media-breakpoint-down(md) {
				text-align: center;
				img {
					max-width: 200px;
				}
			}
		}

		.slogan {
			font-size: 1em;
			font-style: italic;
			color: $cor_01;
			padding: 0;
			margin: 0;
			@include media-breakpoint-down(md) {
				font-size: 1.5em;
				text-align: center;
			}
		}

		.top-cart {
			@include media-breakpoint-down(xs) {
				display: none;
			}
			background: $cor_02;
			float: right;
			border-radius: 10px;
			position: relative;


			.itens-count {
				background: $cor_01;
				border-radius: 100%;
				width: 26px;
				height: 26px;
				line-height: 26px;
				color: #fff;
				font-weight: 700;
				text-align: center;
				position: absolute;
				right: -8px;
				top: -8px;
				z-index: 1;
			}

			img {
				position: relative;
				padding: 10px 15px;
			}
		}
	}
}

/* =======================================================================
   INSTITUTIONAL MENU
   ======================================================================= */
.base-institutional-menu {
	.navbar-toggler-icon {
		background-image: $navbar-light-toggler-icon-bg;
	}

	.navbar-nav {
		.nav-item {
			.nav-link {
				color: $cor_01;
				text-transform: uppercase;
				font-size: 0.9em;
				padding-right: 0.5rem;
				padding-left: 0.5rem;
			}

			&.active {
				.nav-link {
					color: $cor_02;
				}
			}
		}
	}
}

/* =======================================================================
   BANNER
   ======================================================================= */
.banner {
	position: relative;
	border-bottom: #d0bb85 5px solid;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);

	&:after {
		content: "";
		position: absolute;
		z-index: -1;
		top: 100%;
		bottom: 0;
		width: 100%;
		height: 40px;
		left: 0;
		background: -webkit-radial-gradient(50% -3%, ellipse cover, rgba(00, 00, 00, 0.5), rgba(97, 97, 97, 0.0) 40%);
		background: radial-gradient(ellipse at 50% -3%, rgba(00, 00, 00, 0.5), rgba(97, 97, 97, 0.0) 40%);
	}
}

/* =======================================================================
   ACTION BANNER
   ======================================================================= */
.action-banner {
	background: $cor_02;

	h2 {
		text-align: center;
		color: $cor_01;
		font-size: 1em;
		font-weight: 600;
		padding: 10px 0;
		margin: 0;
	}

	a {
		font-weight: 700;
		color: #ffffff;

		&:hover {
			color: $cor_01;
		}
	}
}

/* =======================================================================
   NEW PRODUCTS
   ======================================================================= */
.new-products {
	h4 {
		text-align: center;
		text-transform: uppercase;
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 100%;
		transform: translateX(-50%);
	}

	.basis-new-products {
		border: solid 15px #f7f7f7;
		margin-bottom: 15px;
		position: relative;
		padding-bottom: 35px;
	}

	.featured-products {

		div {
			@include media-breakpoint-down(md) {
				min-height: auto;
			}
			position: relative;
		}
	}
}

/* =======================================================================
   OUR CATEGORIES
   ======================================================================= */
.our-categories {
	.row + .row {
		padding-bottom: 80px;
		@include media-breakpoint-down(md) {
			padding-bottom: 0;
		}
	}

	.unit-category {
		text-align: center;
		@include media-breakpoint-down(md) {
			padding-bottom: 30px;
		}

		h5 {
			padding-top: 10px;
			color: $cor_01;
		}
	}
}

/* =======================================================================
   STEP BY STEP LOCATION
   ======================================================================= */
.step-by-step-location {
	padding: 0 0 50px 0;
	background: $cor_06;

	.steps {
		text-align: center;
		color: $cor_01;
		font-size: 1.4em;

		.call-to-action {
			font-family: 'Dancing Script', sans-serif;
			font-size: 1.6em;
			border-left: solid 2px $cor_01;
			@include media-breakpoint-down(md) {
				border-left: none;
			}

			a {
				font-family: 'Sintony', sans-serif;
				font-size: .5em;
				padding: 15px 20px;
				display: inline-block;
				color: #ffffff;
				background: $cor_02;
				border: 3px solid $cor_01;
				border-left: none;
				border-right: none;
				border-radius: 50px;
				@include animado();

				&:hover {
					border-color: $cor_02;
					background: $cor_01;
					text-decoration: none;
				}
			}
		}
	}
}

/* =======================================================================
   MOUNTING SUGGESTIONS
   ======================================================================= */
.mounting-suggestions {
	padding-bottom: 80px;

	.suggestion {
		@include media-breakpoint-down(md) {
			margin-top: 30px;
		}
		position: relative;

		a {
			&:before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				box-shadow: inset 0 0 0 15px rgba(208, 187, 133, .5);
				box-sizing: border-box;
			}
		}

	}

	.suggestion + .suggestion {
		margin-top: 30px;
	}

	.more-pictures {
		font-family: 'Dancing Script', sans-serif;
		text-align: right;
		color: $cor_01;
		font-size: 2em;
		padding-top: 10px;

		& a:hover {
			color: $cor_01;
			text-decoration: none;
		}
	}
}

/* =======================================================================
   VISIT OUR SHOWROOM
   ======================================================================= */
.visit-our-showroom {
	background: url('../images/banners/banner-showroom.jpg') center center no-repeat;

	.wine-background {
		background: rgba($cor_01, 0.8);
		background: -moz-linear-gradient(-45deg, rgba($cor_01, 0.8) 0%, rgba($cor_01, 0.8) 60%, rgba($cor_01, 0) 60%, rgba($cor_01, 0) 100%);
		background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba($cor_01, 0.8)), color-stop(60%, rgba($cor_01, 0.8)), color-stop(60%, rgba($cor_01, 0)), color-stop(100%, rgba($cor_01, 0)));
		background: -webkit-linear-gradient(-45deg, rgba($cor_01, 0.8) 0%, rgba($cor_01, 0.8) 60%, rgba($cor_01, 0) 60%, rgba($cor_01, 0) 100%);
		background: -o-linear-gradient(-45deg, rgba($cor_01, 0.8) 0%, rgba($cor_01, 0.8) 60%, rgba($cor_01, 0) 60%, rgba($cor_01, 0) 100%);
		background: -ms-linear-gradient(-45deg, rgba($cor_01, 0.8) 0%, rgba($cor_01, 0.8) 60%, rgba($cor_01, 0) 60%, rgba($cor_01, 0) 100%);
		background: linear-gradient(135deg, rgba($cor_01, 0.8) 0%, rgba($cor_01, 0.8) 60%, rgba($cor_01, 0) 60%, rgba($cor_01, 0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#480718', endColorstr='#480718', GradientType=1);
		@include media-breakpoint-down(md) {
			background: none;
			div[class*="col-md"] {
				background: rgba($cor_01, 0.8);
			}
		}
	}

	.call-area {
		h2 {
			color: $cor_05;

			&:after {
				background: $cor_05;
			}

			margin-bottom: 20px;
		}

		h3 {
			color: #ffffff;
		}

		p {
			color: #ffffff;
			text-align: justify;
			line-height: 2em;
		}
	}
}

/* =======================================================================
   FOOTER
   ======================================================================= */
footer {
	background: #eeedeb;
	padding: 40px 0;
	position: relative;
	@include media-breakpoint-up(md) {
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: calc(50% - 20px);
			height: 2px;
			width: 40%;
			background: #b88625;
		}
		&:after {
			content: "";
			position: absolute;
			right: 0;
			top: calc(50% - 20px);
			height: 2px;
			width: 40%;
			background: #b88625;
		}
	}

	p {
		text-align: center;
		padding: 0;
		margin: 0;

		span {
			display: block;
			padding-top: 20px;
			font-size: .8em;
			color: $cor_01;
		}

	}
}

/* =======================================================================
   ########################### CATEGORY PAGE #############################
   ======================================================================= */

/* =======================================================================
   PRODUCTS LIST
   ======================================================================= */
.products-list {

	// CATEGORY LIST
	.category-list {
		padding-top: 40px;

		.page-category &, .page-search & {
			@include media-breakpoint-up(md) {
				margin-top: 260px;
				margin-bottom: 80px;
			}
		}

		.page-product & {
			@include media-breakpoint-up(md) {
				margin-top: 60px;
				margin-bottom: 80px;
			}
		}

		.navbar {
			padding: .5rem 0;
		}

		h4 {
			background: $cor_03;
			text-align: center;
			color: $cor_01;
			padding: 10px 0;
			font-size: 1em;
			margin: 0;
		}

		ul {
			list-style: none;
			padding: 0;

			li {
				padding: 5px 0;

				&:first-child a .row {
					border-top: solid $cor_03 1px;
				}

				a {
					padding: 0;

					&:hover, &.active {
						color: $cor_04;
					}

					.row {
						padding: 2px 0;
						border-bottom: solid $cor_03 1px;

						h5 {
							margin: 0;
							font-size: 1em;
							padding-left: 10px;
							text-align: right;
						}

						.col-3 {
							border-right: solid $cor_03 1px;
							padding-right: 5px;
						}

						.col-11 {
							padding-right: 10px;
						}

					}
				}
			}
		}
	}

	// PRODUCTS
	.products {
		h2 {
			color: $cor_04;
			font-size: 1.1em;
			min-height: 2.5em;
		}

		a {
			position: relative;

			&[data-fancybox="images"] {
				display: block;
			}

			img {
				border: solid 5px #f7f7f7;

				&.icon-zoom {
					border: none;
					position: absolute;
					right: 10px;
					bottom: 10px;
				}
			}
		}

		.row {
			padding-bottom: 50px;

			&:last-child {
				padding-bottom: 0;
			}
		}

		.byproducts {
			.row {
				padding: 10px 0;

				h4 {
					font-size: 0.7em;
					margin: 0;
					padding: 0;
				}

				.number-product {
					input {
						border-color: $cor_02;
					}

					button {
						border-color: $cor_02;
						background: $cor_01;

						strong {
							color: #ffffff;
							font-weight: 700;
						}
					}
				}
			}
		}

		.add-product-details {
			text-align: right;
			padding: 20px 0;

			a, button {
				border-radius: 3px;
				text-transform: uppercase;
				border-color: $cor_02;
				@include animado();

				&:hover {
					background: $cor_01;
					color: #fff;
				}
			}

			a {
				background: #fff;
				color: $cor_01;
			}

			button {
				background: $cor_02;
				color: #fff;
			}
		}

		.checkout-btn {
			text-align: center;
			padding: 20px;

			a {
				font-weight: 700;
				font-size: 1.1em;
				background: $cor_01;
				color: #ffffff;

				&:hover {
					background: $cor_01;
					color: $cor_02;
					border-color: $cor_02;
				}
			}
		}
	}

	// PRODUCT
	.product {
		h2 {
			color: $cor_01;
			font-size: 2em;
			min-height: 1.4em;
		}

		a {
			position: relative;

			&[data-fancybox="images"] {
				display: block;
			}

			img {
				border: solid 15px #f7f7f7;

				&.icon-zoom {
					border: none;
					position: absolute;
					right: 10px;
					bottom: 10px;
				}
			}
		}

		h5 {
			font-weight: 600;
			margin-top: 20px;
			text-transform: uppercase;
			padding: 0 10px;
		}

		p {
			font-size: .8em;
			line-height: 1.4em;
			text-align: justify;
			padding: 0 10px;
		}

		.row {
			padding-bottom: 50px;
			padding-top: 50px;
		}

		.byproducts {
			.row {
				padding: 10px 0;

				h4 {
					font-size: 0.7em;
					margin: 0;
					padding: 0;
				}

				.number-product {
					input {
						border-color: $cor_02;
					}

					button {
						border-color: $cor_02;
						background: $cor_01;

						strong {
							color: #ffffff;
							font-weight: 700;
						}
					}
				}
			}
		}

		.add-product-details {
			text-align: right;
			padding: 20px 0;

			a, button {
				border-radius: 3px;
				text-transform: uppercase;
				border-color: $cor_02;
				@include animado();

				&:hover {
					background: $cor_01;
					color: $cor_02;
				}
			}

			a {
				background: #fff;
				color: $cor_01;
			}

			button {
				background: $cor_02;
				color: $cor_01;
			}
		}

		.checkout-btn {
			text-align: center;
			padding: 20px;

			a {
				font-weight: 700;
				font-size: 1.3em;
				background: $cor_01;
				color: #ffffff;

				&:hover {
					background: $cor_01;
					color: $cor_02;
					border-color: $cor_02;
				}
			}
		}
	}

	// PAGE PAGINATION
	.page-pagination {
		font-size: .8em;

		.pagination {
			flex-wrap: wrap;

			.page-item {
				&.disabled {
					.page-link {
						color: #6c757d;
					}
				}

				&.active {
					.page-link {
						background-color: $cor_01;
						border-color: $cor_01;
						color: #ffffff;
					}
				}

				.page-link {
					color: $cor_01;
				}
			}
		}
	}

	// PRODUCTS BY PAGE
	.products-by-page {
		padding-top: 15px;
		font-size: 0.8em;

		label {
			display: inline-block;
			padding-right: 5px;
		}
	}
}

/* =======================================================================
   PRODUCTS SEARCH
   ======================================================================= */
.product-search {
	padding-top: 40px;

	button {
		font-weight: 700;
		background: $cor_01;
		color: #ffffff;
	}
}

.active .dropdown-item {
	color: #16181b;
	text-decoration: none;
	background-color: #f8f9fa;
}

/* =======================================================================
   ######################## MOUNTING KIT PAGE ############################
   ======================================================================= */
.mounting-kit {
	.gallery {
		min-height: 600px;
		margin-bottom: 100px;
	}
}

/* =======================================================================
   ######################## CONTACT PAGE ############################
   ======================================================================= */
.contact {
	form {
		min-height: 600px;
		margin-bottom: 100px;

		a, button {
			border-radius: 3px;
			text-transform: uppercase;
			border-color: $cor_02;
			@include animado();

			&:hover {
				background: $cor_01;
				color: $cor_02;
				border-color: $cor_02;
			}
		}

		a {
			background: #fff;
			color: $cor_01;
		}

		button {
			background: $cor_02;
			color: $cor_01;
			font-size: 1.2em;
		}
	}
}

/* =======================================================================
   ######################## QUOTATION PAGE ############################
   ======================================================================= */
.quotation {
	ul {
		list-style-type: decimal-leading-zero;

		li {
			padding: 10px;
		}
	}

	.call-to-action {
		text-align: center;
		padding: 20px;

		a {
			font-weight: 700;
			font-size: 1.3em;
			background: $cor_01;
			color: #ffffff;

			&:hover {
				background: $cor_01;
				color: $cor_02;
				border-color: $cor_02;
			}
		}
	}

	.table-responsive {
		padding-top: 50px;

		thead {
			tr {
				th {
				}

				.product-name {
				}

				.product-quantity {
					width: 10%;
					min-width: 150px;
				}

				.product-update {
					width: 10%;
				}

				.product-remove {
					width: 10%;
				}
			}
		}

		tbody {
			td {
				vertical-align: middle;
			}

			.product-name {
				font-size: .8em;
			}

			.product-quantity {
			}

			.product-update {
				text-align: center;
			}

			.product-remove {
				text-align: center;
			}
		}
	}

	.action-buttons {
		padding: 50px 0;

		a {
			border-radius: 3px;
			text-transform: uppercase;
			border-color: $cor_01;
			@include animado();

			&:hover {
				background: $cor_01;
				color: $cor_02;
				border-color: $cor_02;
			}
		}

		a {
			background: $cor_01;
			color: #ffffff;
			font-size: 1.2em;
		}

		.finish-button {
			text-align: right;
		}
	}
}

/* =======================================================================
   ###################### QUOTATION FINISH PAGE ##########################
   ======================================================================= */

.quotation-finish {
	form {
		fieldset {
			h2 {
				color: $cor_01;
				font-weight: 700;
				border-bottom: solid 1px #cccccc;
				padding-bottom: 3px;
				margin-bottom: 20px;
			}

			.form-row, .row {
				padding-bottom: 20px;

				.custom-checkbox, .custom-radio {
					padding-bottom: 10px;

					.custom-control-input.alert {
						position: absolute;
					}

					.custom-control-label {
						font-size: .8em;
						padding-top: 3px;
					}
				}

				.password-recovery {
					font-size: .6em;
					padding-left: 10px;
				}
			}

			.tab-pane {
				padding-top: 20px;
			}
		}
	}
}

/* =======================================================================
   ###################### EARLY WARNING ##########################
   ======================================================================= */
#early-warning {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, .3);

	#early-warning-image {
		position: absolute;
		top: calc(50% - 210px);
		@include media-breakpoint-up(md) {
			left: calc(50% - 400px);
		}
		z-index: 999;

		.btn-close {
			font-size: 18px;
			font-weight: bold;
			color: #fff;
			padding: 10px 15px;
			background: #000;
			position: absolute;
			top: 0;
			right: 0;
			display: block;
		}
	}
}
